import 'idempotent-babel-polyfill';
import Helpers from '../Helpers';

$(function() {

    if ($('#plusapp_recommend_order').length > 0) {
        return;
    }

    $('script[src*="recommend-order.js"]').before('<div id="plusapp_recommend_order"></div>');
    let oMainContainer = $('#plusapp_recommend_order');
    let oHelper = new Helpers();
    let sModule = 'recommend-order';
    let sAppUrl = oHelper.getAppUrl(sModule);
    let oAppUrl = oHelper.getUrlAsObject(sAppUrl);
    let sAdminUrl = (oAppUrl.root_domain !== 'local') ? `${oAppUrl.subdomain}.${EC_ROOT_DOMAIN}` : sAppUrl;
    let sPlusappManagerAdminUrl = new URLSearchParams(window.location.search).get('PLUSAPP_MANAGER_ADMIN_URL');
    const sUserAgent = navigator.userAgent.toLowerCase();
    if (oHelper.isPreviewMode() === true) {
        $('body').css('pointer-events','none');
    }
    /**
     * Contains the expected mobile client for this script
     *
     * @type {{chrome: {getVersion(): string, name: string, check(): boolean}, android: {name: string, openAppStore(): void, check(): boolean}, ios: {getVersion(): number, name: string, openAppStore(): void, check(): boolean}}}
     */
    let oMobileClients = {
        ios: {
            name: 'ios',
            /**
             * Check if the Useragent for IOS is valid
             * @returns {boolean}
             */
            check() {
                return /iphone|ipad/.test(sUserAgent);
            },
            /**
             * Open the app store through ios deep link
             */
            openAppStore() {
                const oMobileInfo = oRecommendOrder.service_info['ios_production_information'];
                oHelper.openIosApp(oMobileInfo.app_id, oMobileInfo.apple_linkid, location.href);
            },
            /**
             * Get The Version of IOS
             * @returns {number}
             */
            getVersion() {
                return parseFloat(sUserAgent.substr(sUserAgent.search(/ipad|iphone/), 30).match(/\d+_\d+/)[0].replace('_', '.'));
            }
        },
        android: {
            name: 'android',
            /**
             * Check if the Useragent for Android is valid
             * @returns {boolean}
             */
            check() {
                return /android/.test(sUserAgent);
            },
            /**
             * Open the app store through android deep link
             */
            openAppStore() {
                const oMobileInfo = oRecommendOrder.service_info['android_production_information'];
                const bIsChrome = oMobileClients.chrome.check();
                let oOpenAppIframe = $('#open_app_iframe');

                if (oOpenAppIframe.length === 0) {
                    $("body").append("<iframe id='open_app_iframe' style='display: none'></iframe>");
                    oOpenAppIframe = $("#open_app_iframe");
                }
                let openAt = new Date;
                setTimeout(function () {
                    if ((new Date - openAt) < 2000) {
                        oOpenAppIframe.attr('src', `market://details?id=${oMobileInfo.google_linkid}`);
                    }
                }, 200);
                let sAppLink = `${oMobileInfo.app_id}://`;
                if (location.href) {
                    sAppLink += `web?url=${encodeURIComponent(location.href)}`;
                }
                if (bIsChrome) {
                    location.href = `intent://default?version=1#Intent;scheme=${sAppLink};package=${oMobileInfo.google_linkid};S.browser_fallback_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3D${oMobileInfo.google_linkid};end`;
                } else {
                    oOpenAppIframe.attr('src', sAppLink);
                }
            },
        },
        chrome: {
            name: 'chrome',
            /**
             * Check if the useragent is chrome
             * @returns {boolean}
             */
            check() {
                return (sUserAgent.search('chrome') > -1) && (this.getVersion() > 25);
            },
            /**
             * Get the chrome version (this is needed for ios)
             * @returns {string}
             */
            getVersion() {
                return  navigator.appVersion.match(/Chrome\/\d+.\d+/)[0].split("/")[1];
            }
        }
    };

    /**
     * Contains the main procedures for this script
     *
     * @type {{shop_info: null, fetchSettings(*): void, detected_client: null, main_container: *, service_info: null, appendButton(*=): void, user_agent: null}}
     */
    let oRecommendOrder = {
        detected_client: null,
        service_info: null,
        shop_info: null,
        user_agent: null,
        main_container: oMainContainer,
        shop_no: EC_SDE_SHOP_NUM,
        mall_id: EC_FRONT_JS_CONFIG_MANAGE.sMallId,
        plusapp_url: oHelper.getAppUrl(sModule),
        /**
         * Get Request from the APIfetchSettings
         * @param oButtonRender
         */
        fetchSettings(oButtonRender) {
            const oThis = this;
            $.ajax({
                method: 'GET',
                url: `${oThis.plusapp_url}/api/front/${oThis.mall_id}/recommend-order?shop_no=${oThis.shop_no}`,
                success: function (oData) {
                    oRecommendOrder.shop_info = oData.data.multi_shop_info;
                    oRecommendOrder.service_info = oData.data.service_info;
                    const oSettings = oData.data.recommend_order_data;
                    let bRecommendDisabled =
                        oRecommendOrder.shop_info.plusapp_manager_enabled === false ||
                        oRecommendOrder.service_info.service_available === false ||
                        oThis.detected_client === null ||
                        oSettings.use_order_button === 'F' ||
                        oSettings.use_order_button === 0 ||
                        oSettings.use_order_button === false ||
                        oHelper.isUnsupportedAgent() === true;

                    if (bRecommendDisabled === true && oHelper.isPreviewMode() === false) {
                        return; // one of the conditions failed; thus, we don't proceed anymore
                    }

                    //If OS is not available
                    if (oRecommendOrder.service_info.oOsDisplay[client().name] !== true && oHelper.isPreviewMode() === false) {
                        return;
                    }

                    oButtonRender(oSettings);
                    window.addEventListener('message', ({origin, data:{settings, module}}) => {
                        if (origin !== sAdminUrl) {
                            return false;
                        }

                        // origin checker - don't execute the code when the origin not matched
                        document.getElementById('plusapp_recommend_order').innerHTML = '';
                        oButtonRender(settings)
                    });
                }
            });
        },
        /**
         * Append Button to the Front
         * @param oSettings
         */
        appendButton(oSettings) {
            // determine which procedure to generate button
            let sCreateFunction = ({
                image: 'createImageTemplate',
                text: 'createTextTemplate'
            })[oSettings.button_type];

            // call procedure
            let sButtonHtml = oButton[sCreateFunction](oSettings);
            oRecommendOrder.main_container.append(sButtonHtml);
            if (oHelper.isPreviewMode() === true) {
                document.documentElement.scrollTop = document.getElementById('plusapp_recommend_order').offsetTop
            }
            $(`#${oButton.id}`).click( async function(e) {
                let sOsType = client().name === 'android' ? 'Android' : 'iOS';
                e.preventDefault();
                await oHelper.logAppstoreRedirects(sOsType, 'order_recomm', oSettings.shop_no, oSettings.mall_id, oRecommendOrder.plusapp_url, 'recommend_order');
                client().openAppStore();
            });

            // Will send a message only if the main / parent window is Plusapp Manager Admin
            // Prevents console error
            let rUrl = new RegExp(sAdminUrl);
            if (sPlusappManagerAdminUrl !== null  && rUrl.test(sPlusappManagerAdminUrl)) {
                let oAdminMessage = {isScriptLoaded: true, module: sModule};
                window.parent.postMessage(oAdminMessage, sAdminUrl);
            }
        }
    };

    /**
     * Object that contains the details on how to create the recommend order button
     *
     * @type {{createImageTemplate(*): string, createTextTemplate(*): string, id: string, generateDefaultUrl(*): string}}
     */
    let oButton = {
        id: 'plusapp_recommend_order_link',

        /**
         * Generate Default Url Banner
         * @param sLanguage
         * @returns {string}
         */
        generateDefaultUrl(sLanguage) {
            return `//img.echosting.cafe24.com/skin/mobile_${sLanguage}/app/img_app_order_banner.gif`;
        },

        /**
         * Adjust Line Height based on Text size
         * @param mTextSize
         * @param iAddSize
         * @returns {*}
         */
        adjustLineHeight(mTextSize, iAddSize) {
            return (parseInt(mTextSize) + iAddSize);
        },

        /**
         * Create Dom Image Template
         * @param oSettings
         * @returns {string}
         */
        createImageTemplate(oSettings) {
            let sUrl = '';

            if (oSettings.image_button_type === 'default' && oSettings.image_design_type === 'A') {
                let sIntroBg = oHelper.createRgbaFromHex(
                    oSettings.intro_background_color,
                    oSettings.intro_bg_transparency
                );
                let sAppBg = oHelper.createRgbaFromHex(
                    oSettings.app_button_background_color,
                    oSettings.app_button_bg_transparency
                );
                return `
                <div class="pa-recommend-order" style="display: flex;flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background: #FFFFFF;
                    padding:5px;">
                    <span style="padding: 10px;width: 87%;text-align: center;border-top-right-radius: 4px;border-top-left-radius: 4px;
                        background: ${sIntroBg};
                        color: ${oSettings.intro_text_color};
                        font-size: ${oSettings.intro_text_size + 'px'};
                        line-height: ${this.adjustLineHeight(oSettings.intro_text_size, 5) + 'px'};
                        white-space: pre-line;
                        word-break: break-all;"
                    >${oHelper.encodeElementTags(oSettings.image_intro_text)}</span>
                    <a id="${this.id}" href="javascript:;" style="width: 87%;padding: 10px;text-align: center;border-bottom-right-radius: 4px;border-bottom-left-radius: 4px;
                        background: ${sAppBg};
                        color: ${oSettings.app_button_text_color};
                        font-size: ${oSettings.app_button_text_size + 'px'};
                        line-height: ${this.adjustLineHeight(oSettings.app_button_text_size, 5) + 'px'};
                        word-break: break-all;"
                    >${oHelper.encodeElementTags(oSettings.app_button_text)}
                    </a>
                </div>
                `;
            }
            else if (oSettings.image_button_type === 'default' && oSettings.image_design_type === 'default') {
                sUrl = this.generateDefaultUrl(oRecommendOrder.shop_info.language);
            } else {
                sUrl = oSettings.image_button_url;
            }
            return `<a id="${this.id}" style="margin:0 7px 7px; display: block;">
                        <div style="display:block; width:100%; max-width:500px; margin:0 auto; box-sizing:border-box; border:1px solid #d5d5d5;">
                            <img src="${sUrl}" width="100%" alt="쇼핑몰 앱으로 구매하면 앱 전용 혜택과 할인 제공!!">
                        </div>
                    </a>`;
        },

        /**
         * Create Dom Text Template
         * @param oSettings
         * @returns {string}
         */
        createTextTemplate(oSettings) {
            return `<div id="${this.id}" style="margin:0 7px 7px; display: block;">
                        <a href="#none" style="display:block;
                            max-width:500px;
                            margin:0 auto;
                            padding:12px 17px 12px;
                            box-sizing:border-box;
                            border-radius:3px;
                            line-height:${this.adjustLineHeight(oSettings.text_button_font_size, 4) + 'px'};
                            text-align:center;
                            font-size:${oSettings.text_button_font_size + 'px'};
                            color:${oSettings.text_button_title_color};
                            background:${oHelper.createRgbaFromHex(oSettings.text_button_color, oSettings.text_button_transparency)};">
                            ${oHelper.encodeElementTags(oSettings.text_button_title)}
                        </a>
                    </div>`;
        }
    };

    // Detect the valid client
    for (const [client_name, client] of Object.entries(oMobileClients)) {
        if (client.check() === true) {
            oRecommendOrder.detected_client = oMobileClients[client_name];
            break;
        }
    }

    /**
     * Get client
     * @returns {null}
     */
    function client() {
        return oRecommendOrder.detected_client;
    }

    //After detecting the client, run this.
    oRecommendOrder.fetchSettings(oRecommendOrder.appendButton);
});
